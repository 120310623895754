const App = (() => {
  const scrollBarWidth = () => {
    const inner = document.createElement('p');
    const outer = document.createElement('div');

    inner.style.width = '100%';
    inner.style.height = '200px';

    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;

    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;

    if (w1 === w2) {
      w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);
    return (w1 - w2);
  };


  const $window = $(window);
  const $html = $('html');
  const $body = $html.find('body');
  const $header = $body.find('header');
  const $burger = $header.find('.burger-btn');
  const $nav = $header.find('nav');
  const $subItem = $nav.find('.has-submenu > a');
  const $infoTrigger = $header.find('.info-trigger');
  const $infoWrapper = $header.find('.info-wrapper');

  const $footer = $body.find('footer');
  const $footerBurger = $footer.find('.burger-btn');

  const $infoWrapperCloseBtn = $infoWrapper.find('.close-wrapper');

  const $mainSliderWrapper = $body.find('.Main-slider');
  const $mainSlider = $mainSliderWrapper.find('#mainSlider');

  const $partnersSlider = $body.find('#partnersSlider');
  const $teamList = $body.find('#teamList');
  const $reviewSlider = $body.find('#reviewSlider');

  const $advantagesList = $body.find('.advantages-list:not(.no-js)');
  const $advantagesBtn = $advantagesList.find('.w-btn');

  const $timer = $body.find('#timerCallBack');
  const $callBackModal = $body.find('#myModal4');

  const ifMobile = $window.width() <= 1024;
  // const ifDesktop = $window.width() > 1024;
  const scrollBar = ifMobile ? 0 : scrollBarWidth();

  class App {
    static lockBody() {
      $body.css({
        overflow: 'hidden',
        'padding-right': `${scrollBar}px`,
      });
      $header.css({
        'padding-right': `${scrollBar}px`,
      });
    }

    static unlockBody() {
      $body.css({
        overflow: '',
        'padding-right': 0,
      });
      $header.css({
        'padding-right': 0,
      });
    }

    toggleMenu(e) {
      const $this = $(this);
      const BURGER_CLASS = 'open';
      // const DURATION = 300;

      e.preventDefault();
      $this.toggleClass(BURGER_CLASS);
      if ($this.hasClass(BURGER_CLASS)) {
        App.lockBody();
        $header.addClass('opened');
      } else {
        App.unlockBody();
        $header.removeClass('opened');
      }
    }

  stickHeader = () => {
    // const position = $header.offset().top;
    const $logo = $header.find('.logotype');
    const normalLogo = 'logo.png';
    const inverseLogo = 'logo_inverse.png';

    const $logoImg = $logo.find('img');
    const oldSrc = $logoImg.attr('src');

    const logoReg1 = new RegExp(normalLogo);
    const logoReg2 = new RegExp(inverseLogo);

    if ($window.scrollTop() >= 100) {
      $header.addClass('isSticky');
      if (!!$logoImg && logoReg1.test(oldSrc)) {
        const src = oldSrc.split('/').map((item) => (logoReg1.test(item) ? inverseLogo : item)).join('/');

        $logoImg.attr('src', src);
      }
    } else {
      $header.removeClass('isSticky');
      if (!!$logoImg && logoReg2.test(oldSrc)) {
        const src = oldSrc.split('/').map((item) => (logoReg2.test(item) ? normalLogo : item)).join('/');

        $logoImg.attr('src', src);
      }
    }
  };

  switchToDesktop = () => {
    $advantagesBtn.hide();
    $header.removeClass('opened');
    $('.has-submenu').removeClass('open');
    $subItem.off('click', this.handleSubItemClick);
    $window.on('scroll', this.stickHeader);
  };

  switchToMobile = () => {
    $advantagesBtn.show();
    $subItem.on('click', this.handleSubItemClick);
    $window.off('scroll', this.stickHeader);
  };

  setDefaultListeners = () => {
    const self = this;

    function handleAdvantagesBtnClick(e) {
      e.preventDefault();
      $(this).hide();
    }
    $advantagesBtn.on('click', handleAdvantagesBtnClick);
    $burger.on('click', this.toggleMenu);
    $infoTrigger.on('click', this.handleInfoTriggerClick);
    $infoWrapperCloseBtn.on('click', this.handleCloseInfoWrapper);
    $footerBurger.on('click', this.handleFooterMenuShowing);

    $window.on('load', () => {
      customMap();
      self.initVideo();
    });
    this.stickHeader();
  };

  handleFooterMenuShowing = () => $footer.find('nav').slideToggle(300);

  handleCloseInfoWrapper = () => $infoWrapper.hide();

  handleInfoTriggerClick = (e) => {
    e.preventDefault();
    $infoWrapper.show();
  };

  handleSubItemClick = (e) => {
    e.preventDefault();
    $(e.target).parents('.has-submenu').toggleClass('open');
  };

  arrowPrev = '<span class="slick-prev"><span class="text">назад</span><span class="icon-l_arrow"></span></span>';

  arrowNext = '<span class="slick-next"><span class="text">назад</span><span class="icon-r_arrow"></span></span>';

  init = () => {
    const self = this;

    $('.selectpicker select').selectpicker({
      style: 'btn-info',
      size: 4,
    });

    $('.advantages-list.slider-theme').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      slide: '.item',
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });


    this.setDefaultListeners();

    $body.find('[data-toggle="popover"]').popover({
      html: true,
      placement: 'bottom',
    });
    $mainSlider.slick({
      slide: '.item',
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      fade: true,
    });
    $partnersSlider.slick({
      slide: '.item',
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: true,
      customPadding: 20,
      arrows: false,
      appendArrows: $partnersSlider.find('.controllers'),
      // adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
    $teamList.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      slide: '.slide',
      dots: true,
      arrows: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
    $('.directions-list').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      slide: '.item',
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });

    $reviewSlider.slick({
      slide: '.item',
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
    });
    $callBackModal.on('shown.bs.modal', self.startTimer).on('hidden.bs.modal', self.clearTimer);
  };

  clearTimer = () => this.myTimer.stop();

  myTimer = new Timer({
    onstart(millisec) {
      const sec = Math.round(millisec / 1000);

      $timer.text(`${sec}:00`);
    },
    ontick(millisec) {
      const sec = Math.round(millisec / 1000);

      $timer.text(`${sec}:00`);
    },
    onpause() {
      $timer.text('pause');
    },
    onstop() {
      $timer.text('00:00');
    },
    onend() {
      $timer.text('end');
    },
  });

  initVideo = () => {
    const opt = {
      enabled: true,
      seekTime: 5,
      // volume: 5,
      click: true,
      // debug: true,
      type: 'video',
      title: 'Example title',
    };

    Plyr.setup($('.video-box video'), opt);
  };

  startTimer = () => this.myTimer.start(30);
  }

  return App;
})();
